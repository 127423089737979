body,
html {
  margin: 0;
  padding: 0;
  background-color: #ffd300;
  background-image: linear-gradient(to bottom, #ffd300, #e8a70f);
  line-height: 1.66667;
  color: #000;
  width: 100%;
  border: 0;
}

div,
section,
p,
span,
header,
footer,
ul,
li,
a,
img {
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  font: inherit;
}