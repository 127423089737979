
.proposal-main-section {
    width: 90%;
    border: 0;
    overflow: hidden;
}

.proposal-list-main-section {
    margin: 0;
}

.proposal-un-list-main-section {
    margin-left: 23px;
}